import React, { Component } from "react";
import { MDBDataTable } from 'mdbreact';
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn, MDBIcon } from 'mdbreact';
import axios from 'axios';

const REST_ADDRESS = 'https://fsb.integral45.ru:443/api/';
class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      tabeData: {
        columns: [
          {
            label: 'ID',
            field: 'id',
            sort: 'asc',
            width: 50
          },          
          {
            label: 'Имя',
            field: 'name',
            sort: 'asc',
            width: 150
          },
          {
            label: 'IP адрес',
            field: 'ip',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Договор',
            field: 'contract',
            sort: 'asc',
            width: 150
          }
        ],
        rows: []
      },
      showTable: false,
      showError: false,
      errorMessage: "",
      showLogin: false
    };

    this.loginRef = React.createRef();
    this.passwordRef = React.createRef();
    axios.defaults.withCredentials = true;
  } 


  loadUsers(){
    axios.get(REST_ADDRESS + 'userlist')
    .then(result => {
      var userList=[];
      result.data.forEach(element => {
        let userRow={
          id: element.id,
          name: element.name,
          ip: element.ip,
          contract: element.contract
        };
        userList.push(userRow);
      });

      let tabeData = Object.assign({}, this.state.tabeData);
      tabeData.rows=userList;
      this.setState({tabeData, showTable: true, showError: false, showLogin: false});
      this.setState({tabeData});
    })
    .catch(error=>{
      if (error.request.status===403){
        this.setState({showTable: false, showError: false, showLogin: true});
      } else {
        this.setState({showTable: false, showError: true, showLogin: false});
      }
      
      console.log(error);
    })
  }


  loginToAPI(){
    let authData={}
    authData.login = this.loginRef.current.state.innerValue;
    authData.password = this.passwordRef.current.state.innerValue;
    axios.post(REST_ADDRESS + 'auth', authData)
    .then (result => {
      this.loadUsers()
    })
    .catch(error => {
      if (error.request.status===403){
        alert("Неправильный логин или пароль");
      } else {
        this.setState({showTable: false, showError: true, showLogin: false});
      }
    })

  }

  loginClick = () => {
    this.loginToAPI();    
  }

  loadUsersClick = () => {
    this.loadUsers();
  }

  componentDidMount(){
    this.loadUsers();
  }

  render() {
    let table;
    if (this.state.showTable){
      table = (
          <MDBDataTable
            striped
            bordered
            hover
            data={this.state.tabeData}
          />
      );
    } else {
      table = "";
    }

    let loginForm;
    if (this.state.showLogin){
      loginForm = (
          <MDBRow className="h-100 justify-content-center align-items-center">
            <MDBCol md="6" >
                <p className="h5 text-center mb-4">Вход</p>
                <div className="grey-text">
                  <MDBInput
                    ref={this.loginRef}
                    label="Введите логин"
                    icon="user"
                    group
                    type="text"
                    validate
                    error="wrong"
                    success="right"
                  />
                  <MDBInput
                    ref={this.passwordRef}
                    label="Введите пароль"
                    icon="lock"
                    group
                    type="password"
                    validate
                  />
                </div>
                
                <div className="text-center">
                  <MDBBtn onClick={this.loginClick}>Вход</MDBBtn>
                </div>
            </MDBCol>
          </MDBRow>
      )
    } else {
      loginForm = "";
    }

    let errorForm;
    if (this.state.showError){
      errorForm=(
        <MDBRow className="h-100 justify-content-center align-items-center">
          <MDBCol md="12">
            <p align="center"><MDBIcon icon="times" size="5x" className="red-text pr-3" />Упс... На сервере произошла ошибка.</p>
          </MDBCol>
        </MDBRow>
      );
    }
    return (
      
      <MDBContainer className="h-100">
        {table}
        {loginForm}
        {errorForm}
      </MDBContainer>
    );
  }
}

export default App;
